
html {
  scroll-behavior: initial !important;
}
/* Scrollbar */
/* ::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background: #fff;
}
::-webkit-scrollbar-thumb {
  background: #babac0;
  border-radius: 10px;
  border: 3px solid #fff;
}
::-webkit-scrollbar-thumb:hover {
  opacity: 0.3;
} */
/* body {padding-top: 70px;} */
/* Header */
.navbar .navbar-nav .nav-link:hover {
  color: #444444;
}
.container {
  max-width: 95% !important;
}
/* DO NOT FORGET TO FIX RESPONSIVE ON HOME PAGE */
@media only screen and (min-width: 960px) {
  .navbar .navbar-nav .nav-link {
    padding: 1em 0.7em;
  }
  .navbar {
    padding: 0;
  }
  .navbar .navbar-brand {
    padding: 0 0.7em;
  }
}
.mobile-cover-header {
  display: none;
}
@media only screen and (max-width: 768px) {
  .desktop-cover-header {
    display: none;
  }
  .cover-default {
    left: initial !important;
  }
  .mobile-cover-header {
    display: block;
  }
  .team-photo {
    width: 100%;
    height: auto;
  }
}
.team-photo {
  width: 80%;
  height: auto;
}
.navbar .navbar-nav .nav-link {
  position: relative;
}
.navbar .navbar-nav .nav-link::after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #444444;
  color: transparent;
  width: 0%;
  content: '';
  height: 3px;
  transition: all 0.5s;
}
.navbar .navbar-nav .nav-link:hover::after {
  width: 60%;
}
.navbar-light .navbar-nav .active::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60%;
  content: " ";
  border-bottom: 3px solid #444444;
}

/* Footeer */
.privacy-policy-link:hover {
  color: #444 !important;
}
.footer-social {
  padding: 0 8px;
  color: #bbb;
}
.footer-social:hover {
  color: #444;
}

.cover-wrapper {
  height: 100vh;
  background-image: url('../src/assets/Cover/exterior.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.cover {
  height: 100vh;
  background-image: url('../src/assets/Cover/exterior.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  display: flex;
  align-items: flex-end;
  margin: 0 !important;
}

.cover-section {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  height: 88%;
  padding: 0 !important;
}

.cover-default {
  position: absolute;
  left: 50px;
  top: 20px;
}


/* Jumbotron */
/* .jumbo {
  background: url(./assets/cover.jpg) no-repeat center;
  background-size: cover;
  color: #ccc;
  height: 870px;
  position: relative;
  z-index: 0;
}
.overlay {
  background-color: #444;
  opacity: 0.1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
} */
.jumbo-socials {
  z-index: 2;
  position: absolute;
  bottom: 50px;
}
.cover-social {
  padding: 0 8px;
  color: #bbb;
}
.cover-social:hover {
  color: #fff;
}

/* mision */

/* Animated Heading */
.animated-heading-bottom {
  animation-duration: 1.5s;
  animation-name: slidein-bottom;
  animation-iteration-count: 1;
}
@keyframes slidein-bottom {
  from {
    margin-bottom: 15%;
    width: 100%;
  }

  to {
    margin-top: 0%;
    width: 100%;
  }
}
.animated-heading-left {
  animation-duration: 1s;
  animation-name: slidein-left;
  animation-iteration-count: 1;
}

@keyframes slidein-left {
  from {
    margin-left: -200%;
    width: 0%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
.animated-heading-opacity {
  animation-duration: 5s;
  animation-name: slidein-opacity;
  animation-iteration-count: 1;
}
@keyframes slidein-opacity {
  from {
    opacity: 0;
    width: 100%;
  }

  to {
    opacity: 1;
    width: 100%;
  }
}

/* Contacts */
.contacts-link {
  color: #444;
  text-decoration: none;
}
.contacts-link:hover {
  color: #bbb;
}

/* Main Services */
.services-main-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
}
.main-services {
  border-right: 1px solid #bbb;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 600;
  color: #444;
  transition: all 500ms ease-out;
  min-height: 233px;
  height: auto;
}
@media only screen and (min-width: 1460px) {
  .main-services {
    min-height: 300px;
  }
}
@media only screen and (min-width: 1960px) {
  .main-services {
    min-height: 350px;
  }
}
.main-services:hover {
  background-size: cover;
  background-repeat: no-repeat;
  border: none;
  color: #fff;
  letter-spacing: 0.04rem;
}
.main-services:last-child {
  border-right: none;
}
@media only screen and (max-width: 992px) {
  .services-main-section {
    display: none;
  }
}
.main-bim-modeling:hover {
  background-image: url(../src/assets/Services/BIM_Service.jpg);
}
.main-architectural-design:hover {
  background-image: url(../src/assets/Services/ArchDesignService.jpg);
}
.main-interior-design:hover {
  background-image: url(../src/assets/Services/InteriorDesignService.jpg);
}
.main-architectural-visualization:hover {
  background-image: url(../src/assets/Services/ArchVisualizationService.jpg);
}

/* ABOUT */

.video-background-holder {
  position: relative;
  background-color: black;
  height: calc(100vh - 72px);
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}

.video-background-holder video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.video-background-content {
  position: relative;
  z-index: 2;
}

.video-background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #444;
  opacity: 0.5;
  z-index: 1;
}

/* Home portfolio gallery animation */
.portfolio-gallery img, .link-wrapper img {
  transition: transform 1s ease;
}
.portfolio-gallery img:hover, .link-wrapper img:hover {
  transform: scale(1.1);
}

.nav-link{
  color: #0000008C !important;
}
.portfolio-tabs .active, .portfolio-tabs .nav-link:hover {
  color: #444 !important;
  box-shadow: inset 0 -2px 0 #444 !important;
}
.portfolio-tabs {
  font-weight: 500;
}
.btn-link:hover {
  color: #444 !important;
}
.lh-sm, .about-us-description {
  text-align: justify;
}

.tab-content {
  display: block;
}

.caption-wrapper {
  background: rgba(0, 0, 0, 0.4);
  max-height: 240px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #fff;
  padding: 10px 40px;
  font-size: 18px;
  z-index: 1;
}
.caption-wrapper > * {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  width: 70%;
  padding-left: 10px;
}
.caption-wrapper a {
  color: #fff;
  text-decoration: none;
}
.footerCaption_2r5qf{
  display: flex;
  justify-content: center;
}
.footerCaption_2r5qf  a {
  padding: 22px 80px;
  margin-bottom: 10px;
  border: none;
  border-radius: 0.2rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #fff;
  background: #ff9800;
  font-weight: 300;
  font-size: 22px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 40%);
  transition: color .4s,background-color .4s,border-color .4s,box-shadow .4s;
}
.footerCaption_2r5qf  a:hover {
  background: #e08600;
  border: none;
  color: #fff;
}
.figure_10ki57k img {
  max-height: calc(100vh - 120px) !important;
}

.go-to-portfolio-btn {
  color: #444;
  text-decoration: none;
  margin: 5px;
  font-size: 1.4rem;
  font-weight: 400;
}
.go-to-portfolio-btn:hover {
  color: #444;
  border-bottom: 1px solid #444;
}
.go-to-portfolio-btn:hover i {
  padding-left: 20px;
  transition: all 300ms ease-out;
}

.site-plan-background {
  background-image: url('../src/assets/Projects/SubwayHub/SitePlan/Site\ Plan.jpg');
  background-size: cover;
}
.site-plan-link {
  text-decoration: none;
  color: #337ab7;
  font-size: 20px;
  font-weight: 300;
  background: none;
  border: none;
}
.site-plan-link:hover {
  color: #2196f3;
}